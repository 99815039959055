import ApiService from "@/core/services/ApiService";
import { CpmiData } from "@/core/types/gws-agent/cpmi-detail/CpmiData";
import { ApiResponse } from "@/core/types/misc/ApiResponse";
import { Option } from "@/core/types/misc/Option";
import { AxiosResponse } from "axios";
import { defineStore } from "pinia";
import { MasterCpmiStatus } from "@/core/config/MasterCpmiStatus";
import { CPMIStatusEnum } from "@/store/enums/StatusEnums";
import { ListItem as BlkItem } from "@/core/types/gws-ap2tki/ListItem";
import {
  ApplicationItem,
  MedicalServiceApplication,
} from "@/core/types/gws-global-feature/cpmi/MedicalServiceApplication";
import {
  DigitalResult,
  ResultDetail,
} from "@/core/types/gws-medical/digital-data/DigitalResult";
import { ListItem as JobFairItem } from "@/core/types/gws-master/master/jobfair/ListItem";
import { ListItem as LspItem } from "@/core/types/gws-ap2tki/lsp/ListItem";
import { LspWrapper } from "@/core/types/gws-master/master/lsp/LspWrapper";

export const useCpmiDetailListStore = defineStore({
  id: "cpmiDetailListStore",
  state: () => {
    return {
      stateLoading: false,
      stateCpmiData: {} as CpmiData,
      stateCpmiApplicationData: [] as ApplicationItem[],
      stateCompanyOption: [] as Option[],
      stateSyarikahOption: [] as Option[],
      stateAgentOption: [] as Option[],
      stateJobFairOption: [] as Option[],
      stateBlkById: {} as BlkItem,
      stateLspById: {} as LspItem,
      stateJobFairDetail: {
        province: {
          id: "",
        },
        city: {
          id: "",
        },
        id: "",
      } as JobFairItem,
      stateMasterCpmiStatus: CPMIStatusEnum,
      stateDigitalResult: {} as ResultDetail,
    };
  },
  getters: {
    loading(state) {
      return state.stateLoading;
    },
    cpmiData(state) {
      return state.stateCpmiData;
    },
    companyOption(state) {
      return state.stateCompanyOption;
    },
    agentOption(state) {
      return state.stateAgentOption;
    },
    syarikahOption(state) {
      return state.stateSyarikahOption;
    },
    jobFairs(state) {
      return state.stateJobFairOption;
    },
    masterStatus(state) {
      return state.stateMasterCpmiStatus;
    },
    cpmiMedicalApplication(state) {
      return state.stateCpmiApplicationData;
    },
    digitalData(state) {
      return state.stateDigitalResult;
    },
    jobFairData(state) {
      return state.stateJobFairDetail;
    },
  },
  actions: {
    async getCpmiDetail(cpmiId: string) {
      this.stateLoading = true;
      this.stateCpmiData = {} as CpmiData;
      this.stateLoading = true;
      try {
        const response: AxiosResponse<ApiResponse<CpmiData>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `cpmi/${cpmiId}`
          );
        const detail = response.data.data;
        this.stateCpmiData = detail;
      } catch (error) {
        console.log(error);
      }
      this.stateLoading = false;
    },
    async getBlkDetail(blkId:string) {
      try {
        const response: AxiosResponse<ApiResponse<BlkItem>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `blk/${blkId}`
          );
        const detail = response.data.data;
        this.stateBlkById = detail;
      } catch (error) {
        console.log(error);
      }
    },
    async getLspDetail(lspId: string) {
      try {
        const response: AxiosResponse<ApiResponse<LspWrapper<LspItem>>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `lsp/${lspId}`
          );
        const detail = response.data.data.lsp;
        this.stateLspById = detail;
      } catch (error) {
        console.log(error);
      }
    },
    async getPreMedicalDetail(id: string) {
      this.stateCpmiApplicationData = [];
      try {
        const response: AxiosResponse<ApiResponse<MedicalServiceApplication>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/cpmi/${id}/medical-service-application`
          );
        this.stateCpmiApplicationData =
          response.data.data.medical_service_application;
      } catch (error: any) {
        console.log(error);
      }
      this.stateLoading = false;
    },
    async getDataDigitalResult() {
      this.stateDigitalResult = {
        sertificate_file: "",
      } as ResultDetail;
      const applicationId =
        this.stateCpmiApplicationData[0]?.medical_service_application_id || '';
      const applicationDetailId =
        this.stateCpmiApplicationData[0]?.medical_service_application_detail_id || '';
      try {
        const response: AxiosResponse<ApiResponse<DigitalResult>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/medical-service-application/${applicationId}/medical_service_application_detail/${applicationDetailId}/data-digital-issuer`
          );
        this.stateDigitalResult =
          response.data.data.medical_data_digital_issuer;
      } catch (error: any) {
        console.log(error);
      }
      this.stateLoading = false;
    },
    async getLookupCompany() {
      this.stateCompanyOption = [];
      const params = {
        page: "1",
        limit: "999",
        dir: "DESC",
        sort: "created_at",
      };
      try {
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.get(
          `${process.env.VUE_APP_GWS_API_BASE_URL}`,
          `companies?${new URLSearchParams(params).toString()}`
        );
        const listCompany = response.data.data.p3mi;
        listCompany.forEach((el) => {
          this.stateCompanyOption.push({
            label: el.name,
            value: el.id,
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getLookupSyarikah() {
      this.stateSyarikahOption = [];
      const params = {
        page: "1",
        limit: "999",
        dir: "DESC",
        sort: "created_at",
      };
      try {
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.get(
          `${process.env.VUE_APP_GWS_API_BASE_URL}`,
          `syarikah?${new URLSearchParams(params).toString()}`
        );
        const listCompany = response.data.data.syarikah;
        listCompany.forEach((el) => {
          this.stateSyarikahOption.push({
            label: el.name,
            value: el.id,
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getLookupAgent() {
      this.stateAgentOption = [];
      const params = {
        page: "1",
        limit: "999",
        dir: "DESC",
        sort: "created_at",
      };
      try {
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.get(
          `${process.env.VUE_APP_GWS_API_BASE_URL}`,
          `agents?${new URLSearchParams(params).toString()}`
        );
        const listCompany = response.data.data.agent_p3mi;
        listCompany.forEach((el) => {
          this.stateAgentOption.push({
            label: el.name,
            value: el.id,
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getLookupJobfair() {
      this.stateJobFairOption = [];
      const params = {
        page: "1",
        limit: "999",
        dir: "DESC",
        sort: "created_at",
      };
      try {
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.get(
          `${process.env.VUE_APP_GWS_API_BASE_URL}`,
          `job-fairs?${new URLSearchParams(params).toString()}`
        );
        const listCompany = response.data.data.job_fair;
        listCompany.forEach((el) => {
          this.stateJobFairOption.push({
            label: el.name,
            value: el.id,
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getLookupJobfairDetail(id: string) {
      try {
        const response: AxiosResponse<ApiResponse<JobFairItem>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `job-fairs/${id}`
          );
        this.stateJobFairDetail = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    resetData() {
      this.stateDigitalResult = {} as ResultDetail;
    },
  },
});
