
import { computed, defineComponent, ref } from "vue";
import {
  swalAlertError,
  swalAlertErrorTitle,
  swalAlertInfo,
} from "@/core/helpers/sweet-alert";
import { number } from "yup";

export default defineComponent({
  name: "CpmiStatusTimeline",
  emits: ["selectMenu"],
  setup(props, { emit }) {
    const status = ref([
      {
        value: "PRE_REGISTRATION",
        label: "Pra Pendaftaran",
        can_back: false,
      },
      {
        value: "REGISTRATION",
        label: "Pendaftaran",
        can_back: false,
      },
      {
        value: "PRE_SECURITY",
        label: "Pre-Security Clearance",
        can_back: true,
      },
      {
        value: "MEDICAL",
        label: "Pre-Medical",
        can_back: true,
      },
      {
        value: "BLK",
        label: "Balai Latihan Kerja",
        can_back: true,
      },
      {
        value: "LSP",
        label: "Lembaga Sertifikasi Profesi",
        can_back: true,
      },
      {
        value: "DISNAKER",
        label: "DISNAKER",
        can_back: true,
      },
      {
        value: "MIGRATION",
        label: "Imigrasi",
        can_back: true,
      },
      {
        value: "VISA",
        label: "Pengajuan Visa",
        can_back: true,
      },
      {
        value: "ORIENTATION",
        label: "Orientasi Pra Pemberangkatan",
        can_back: true,
      },
      {
        value: "DEPARTURE",
        label: "Keberangkatan",
        can_back: true,
      },
      {
        value: "SYARIKAH",
        label: "Verifikasi Syarikah",
        can_back: true,
      },
    ]);
    const handleSelectedStep = (i, can_back: boolean) => {
      let active = 0;
      if (props.activeStatus === "PRE_SECURITY_PASSED") {
        active = 3;
      } else if (props.activeStatus === "MEDICAL_COMPLETED_FIT") {
        active = 4;
      } else if (props.activeStatus === "BLK_COMPLETED") {
        active = 5;
      } else if (props.activeStatus === "LSP_COMPLETED") {
        active = 6;
      } else if (props.activeStatus === "MIGRATION_REQUESTED") {
        active = 7;
      } else if (props.activeStatus === "MIGRATION_COMPLETED") {
        active = 8;
      } else if (props.activeStatus === "VISA_COMPLETED") {
        active = 9;
      } else if (props.activeStatus === "ORIENTATION") {
        active = 10;
      } else if (props.activeStatus === "DEPARTURE") {
        active = 11;
      } else {
        active = status.value.findIndex((el) => {
          return props.activeStatus.includes(el.value);
        });
      }
      if (can_back) {
        if (i <= active) {
          emit("selectMenu", i);
        } else {
          swalAlertInfo("Proses belum mencapai step ini.", "Close");
        }
      }
    };
    return {
      status,
      handleSelectedStep,
    };
  },
  props: {
    activeStatus: {
      type: String,
      default: "",
      required: true,
    },
    activeViewPhase: {
      type: Number,
      default: null,
    },
  },
});
