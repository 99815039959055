<template>
  <div class="card p-4">
    <div class="row">
      <template v-for="(st, i) in status" :key="i">
        <div class="d-flex align-items-center gap-4 my-4">
          <div
            class="status-item"
            :class="st.can_back ? 'menu' : ''"
            @click="handleSelectedStep(i, st.can_back)"
          >
            <div
              :class="[
                activeStatus === 'PRE_SECURITY_PASSED' &&
                st.value === 'PRE_SECURITY'
                  ? ''
                  : activeStatus === 'MEDICAL_COMPLETED_FIT' &&
                    st.value === 'MEDICAL'
                  ? ''
                  : activeStatus === 'MEDICAL_COMPLETED_FIT' &&
                    st.value === 'BLK'
                  ? 'active'
                  : activeStatus === 'PRE_SECURITY_PASSED' &&
                    st.value === 'MEDICAL'
                  ? 'active'
                  : activeStatus === 'BLK_COMPLETED' && st.value === 'LSP'
                  ? 'active'
                  : activeStatus === 'LSP_COMPLETED' && st.value === 'DISNAKER'
                  ? 'active'
                  : activeStatus === 'MIGRATION_REQUESTED' && st.value === 'MIGRATION'
                  ? 'active'
                  : activeStatus === 'VISA_REQUESTED' && st.value === 'VISA'
                  ? 'active'
                  : activeStatus === 'VISA_COMPLETED' && st.value === 'ORIENTATION'
                  ? 'active'
                  : activeStatus === 'ORIENTATION' && st.value === 'DEPARTURE'
                  ? 'active'
                  : activeStatus === 'DEPARTURE' && st.value === 'SYARIKAH'
                  ? 'active'
                  : activeStatus === 'SYARIKAH_VERIFICATION' && st.value === 'SYARIKAH'
                  ? 'active'
                  : activeViewPhase === i
                  ? 'active-phase'
                  : '',
              ]"
            >
              {{ st.label }}
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import {
  swalAlertError,
  swalAlertErrorTitle,
  swalAlertInfo,
} from "@/core/helpers/sweet-alert";
import { number } from "yup";

export default defineComponent({
  name: "CpmiStatusTimeline",
  emits: ["selectMenu"],
  setup(props, { emit }) {
    const status = ref([
      {
        value: "PRE_REGISTRATION",
        label: "Pra Pendaftaran",
        can_back: false,
      },
      {
        value: "REGISTRATION",
        label: "Pendaftaran",
        can_back: false,
      },
      {
        value: "PRE_SECURITY",
        label: "Pre-Security Clearance",
        can_back: true,
      },
      {
        value: "MEDICAL",
        label: "Pre-Medical",
        can_back: true,
      },
      {
        value: "BLK",
        label: "Balai Latihan Kerja",
        can_back: true,
      },
      {
        value: "LSP",
        label: "Lembaga Sertifikasi Profesi",
        can_back: true,
      },
      {
        value: "DISNAKER",
        label: "DISNAKER",
        can_back: true,
      },
      {
        value: "MIGRATION",
        label: "Imigrasi",
        can_back: true,
      },
      {
        value: "VISA",
        label: "Pengajuan Visa",
        can_back: true,
      },
      {
        value: "ORIENTATION",
        label: "Orientasi Pra Pemberangkatan",
        can_back: true,
      },
      {
        value: "DEPARTURE",
        label: "Keberangkatan",
        can_back: true,
      },
      {
        value: "SYARIKAH",
        label: "Verifikasi Syarikah",
        can_back: true,
      },
    ]);
    const handleSelectedStep = (i, can_back: boolean) => {
      let active = 0;
      if (props.activeStatus === "PRE_SECURITY_PASSED") {
        active = 3;
      } else if (props.activeStatus === "MEDICAL_COMPLETED_FIT") {
        active = 4;
      } else if (props.activeStatus === "BLK_COMPLETED") {
        active = 5;
      } else if (props.activeStatus === "LSP_COMPLETED") {
        active = 6;
      } else if (props.activeStatus === "MIGRATION_REQUESTED") {
        active = 7;
      } else if (props.activeStatus === "MIGRATION_COMPLETED") {
        active = 8;
      } else if (props.activeStatus === "VISA_COMPLETED") {
        active = 9;
      } else if (props.activeStatus === "ORIENTATION") {
        active = 10;
      } else if (props.activeStatus === "DEPARTURE") {
        active = 11;
      } else {
        active = status.value.findIndex((el) => {
          return props.activeStatus.includes(el.value);
        });
      }
      if (can_back) {
        if (i <= active) {
          emit("selectMenu", i);
        } else {
          swalAlertInfo("Proses belum mencapai step ini.", "Close");
        }
      }
    };
    return {
      status,
      handleSelectedStep,
    };
  },
  props: {
    activeStatus: {
      type: String,
      default: "",
      required: true,
    },
    activeViewPhase: {
      type: Number,
      default: null,
    },
  },
});
</script>
<style lang="scss" scoped>
.status-item {
  font-size: medium;
}
.menu:hover {
  font-weight: 700;
  border-radius: 5px;
  cursor: pointer;
}
.active-phase {
  font-weight: 700;
  border-radius: 5px;
  cursor: pointer;
}
.active {
  font-weight: 700;
  background-color: #2a87ce;
  border-radius: 5px;
  color: #fff;
  padding: 5px 10px 5px 10px;
}
</style>
